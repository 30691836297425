import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { createAlbumApi, deleteAlbumApi, getAllAlbumApi, searchAlbum, sortAlbum, updateAlbum,UploadNewAlbum } from "../Api/Album";

interface IntesetState {
  Album: any[]; // Replace 'any' with the actual type of your Album items
  loading: boolean;
  error: string | null;
}

const initialState: IntesetState = {
  Album: [],
  loading: false,
  error: null,
};

export const createAlbum = createAsyncThunk<IntesetState[], any>(
    'Album/createAlbum',
    async (data: any) => {
    const {value,File}=data

      try {
        const response: AxiosResponse<any, any> = await createAlbumApi(value);

       
        // if(response.data.isSuccess){
        //     UploadNewAlbum({id,File})
        // }
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  
  );
  
  export const getAllAlbum = createAsyncThunk(
    'Album/getAllAlbum',
    async(modeid :any)=>{
      try {
        const response: AxiosResponse<any, any> | undefined = await getAllAlbumApi(modeid );
        console.log(response);
        
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }

  )
  export const deleteAlbumSlice = createAsyncThunk<IntesetState[], any>(
    'Album/deleteAlbumSlice',
    async (id: any) => {
      try {
        const response: AxiosResponse<any, any> | undefined = await deleteAlbumApi(id);
        return response.data.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );
  
  export const updateAlbumSlice = createAsyncThunk<IntesetState[], any>(
    'Album/updateAlbumSlice',
    async (data: any) => {
      try {
        const response= await updateAlbum(data);
        console.log(response)
        return response ;
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );

   // search 
   export const fetchsearchAlbum = createAsyncThunk<IntesetState[], any>(
    'Album/fetchsearchAlbum',
    async (data: any) => {
      try {
        const response:any= await searchAlbum(data);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );

   // sortUser
   export const fetchsortAlbum = createAsyncThunk<IntesetState[], any>(
    'Album/fetchsortAlbum',
    async (sort: any) => {
      try {
        const response:any= await sortAlbum(sort);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );
 const AlbumSlice = createSlice({
  name: 'AlbumSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(createAlbum.fulfilled, (state, action: any) => {
        console.log(action.payload)
    //   state.Album.push(action.payload);
      state.loading = false
    })
    .addCase(createAlbum.pending, (state, action: PayloadAction<any>) => {

        state.loading = true
      })
    .addCase(createAlbum.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
    })
    .addCase(getAllAlbum.fulfilled, (state, action: PayloadAction<any>) => {
        state.Album= action.payload
        state.loading = false
      })
    .addCase(getAllAlbum.pending, (state, action: PayloadAction<any>) => {
  
          state.loading = true
     })
    .addCase(getAllAlbum.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
    })
    .addCase(deleteAlbumSlice.fulfilled, (state, action:any) => {
     
  
        state.loading = false;
      })
       // search
       .addCase(fetchsearchAlbum.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Album = action.payload;
        state.loading = false;
      })

      // fetchsortUser
      .addCase(fetchsortAlbum.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Album = action.payload;
        state.loading = false;
      })

  },
});
export default AlbumSlice.reducer;
