import { configureStore } from "@reduxjs/toolkit";
import UserManagement_slice from "./redux/Slice/UserManagement_slice";
import Modes_Slice from "./redux/Slice/Modes_Slice";
import SubscriptionPlans_Slice from "./redux/Slice/SubscriptionPlans_Slice";
import FaqSlice from "./redux/Slice/FaqSlice";
import BlogArticlesPublishingSlice from "./redux/Slice/BlogArticlesPublishingSlice";
import RatingSlice from "./redux/Slice/RatingSlice";
import AuthSlice from "./redux/Slice/AuthSlice";
import IntersetSlice from "./redux/Slice/IntersetSlice";
import ActivitySlice from "./redux/Slice/ActivitySlice"; 
import SubscriptionSlice from "./redux/Slice/SubscriptionSlice";
import TermsAndConditionsSlice from './redux/Slice/TermAndConditionSlice'
import PrivecyPolicysSlice from "./redux/Slice/PrivecyPolicysSlice";
import SongSlice from "./redux/Slice/SongSlice";
import MovieSlice from "./redux/Slice/MovieSlice";
import AlbumSlice from "./redux/Slice/AlbumSlice";
import TagCategorySlice from "./redux/Slice/TagCategorySlice";
import TagSlice from "./redux/Slice/TagSlice";
import SingerSlice from "./redux/Slice/SingerSlice";
import PlaylistSlice from "./redux/Slice/PlaylistSlice";
import LikeSlice from "./redux/Slice/LikeSlice";
import PodcastSlice from "./redux/Slice/PodcastSlice";
import ShowsSlice from "./redux/Slice/ShowsSlice";
import SeasionSlice from "./redux/Slice/SeasionSlice";
import EpisodesSlice from "./redux/Slice/EpisodesSlice";
import PodcastEpisodeSlice from "./redux/Slice/PodcastEpisodeSlice";

 const store = configureStore({

    reducer: {
        
        user:UserManagement_slice,
        mode:Modes_Slice,
        subscription:SubscriptionPlans_Slice,
        faq:FaqSlice,
        BlogArticlesPublishing:BlogArticlesPublishingSlice,
        Song:SongSlice,
        rating:RatingSlice,
        auth:AuthSlice,
        Subscription:SubscriptionSlice,
        interest:IntersetSlice,
        activity:ActivitySlice,
        Singer:SingerSlice,
        Playlist:PlaylistSlice,     
        TermsAndConditions:TermsAndConditionsSlice,
        PrivecyPolicys:PrivecyPolicysSlice,
        Movie:MovieSlice,
        ShowsSlice:ShowsSlice,
        SeasionSlice:SeasionSlice,
        EpisodesSlice:EpisodesSlice,
        Album:AlbumSlice,
        TagCategory:TagCategorySlice,
        Tag:TagSlice,
        Like:LikeSlice,
        Podcast:PodcastSlice,
        PodcastEpisode:PodcastEpisodeSlice
    },

})
export default store