import axios from "axios";
import  BaseUrl  from "../../BaseUrl";
import apiUrl from '../../BaseUrl'  
const token:any = localStorage.getItem('token');


export const createPodcastApi =async(data:any)=>{
    try {
        const response = await axios.post(`${BaseUrl}podcast/create`,data)
        return response
    } catch (error) {
        console.log(error)
      throw error;   

    }
}

export const getAllPodcastApi = async(modeid :any)=>{
    try {
        const response = await axios.get(`${BaseUrl}podcast/getall/${modeid}?page_no=1&page_size=100`)
        return response
    } catch (error) {
        console.log(error)  
      throw error;   
        
    }
}

export const deletePodcastApi=async(id:any)=>{
   
    try {
        const resoponse = await axios.delete(`${BaseUrl}podcast/remove/${id}`)
        return resoponse
    } catch (error) {
      console.log(error) 
      throw error;   
    }
}
export const updatePodcast = async (data: any) => {
    const { editId, values } = data;
    const options = {
      method:'PUT',
      headers: {
        'content-type':'application/json',
        
      },
      body: JSON.stringify(values),
    };
    try {
      const response:any = await fetch(
        `${apiUrl}podcast/Podcastupdate/${editId}`,
        options
      );
      const dataaa = await response.json()
  

      return  dataaa; // Fixed the variable name here
    } catch (error) {
      console.log(error);
      throw error;
    }
  };


  export const UploadNewSong = async (data:any) => {
    const { File,id,config } = data;
  
    const formData = new FormData();
    formData.append('song', File); 
    
  
    try {
      const response = await axios.put(`${apiUrl}song/uploadSong/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress:config
      })
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };



export const updateStatus = async (data: any) => {
  const { editId, value } = data;
  const options = {
    method:'PUT',
    headers: {
      'content-type':'application/json',
      
    },
    body: JSON.stringify({status:value}),
  };
  try {
    const response:any = await fetch(
      `${apiUrl}/podcast/update/${editId}`,
      options
    );
    const dataaa = await response.json()


    return  dataaa; // Fixed the variable name here
  } catch (error) {
    console.log(error);
    throw error;
  }
};
 

  export const searchUser = async (name: any) => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
  
    try {
      const response = await fetch(
        `${apiUrl}users/search?name=${name}`,
        options
      );
  
      if (!response.ok) {
        throw new Error(`Failed to fetch data. Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log(responseData);
      
      return responseData; // Assuming the response is already JSON, no need to stringify.
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  export const searchPodcast = async (name: any) => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
  
    try {
      const response = await fetch(
        `${apiUrl}podcast/search/6565dbb8f55b057bd1fc4a82?name=${name}`,
        options
      );
  
      if (!response.ok) {
        throw new Error(`Failed to fetch data. Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log(responseData);
      
      return responseData; // Assuming the response is already JSON, no need to stringify.
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
    
  export const sortPodcast =async(sort:any)=>{
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  
    try {
      const response = await fetch(
        `${apiUrl}podcast/sort/6565dbb8f55b057bd1fc4a82?sort=${sort}`,
        options
      );
      const responseData = await response.json();
      console.log(responseData);
      
      return responseData; 
    } catch (error) {
      console.error(error);
      throw error;
    }
  }


 export const UploadNewPodcast = async (data:any) => {
    const { File,id,config } = data;
  
    const formData = new FormData();
    formData.append('image', File); 
    
  
    try {
      const response = await axios.put(`${apiUrl}podcast/uploadPodcastImage/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress:config
      })
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };


  export const createProfile = async (data:any) => {
    const { File,id,config } = data;
    
    const formData = new FormData();
    formData.append('image', File); 
    
  
    try {
      const response = await axios.put(`${apiUrl}podcast/uploadPodcastImage/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress:config
      })
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }}
    export const getbyidPodcast=async(data:any)=>{
  
      const option={
       headers: {
                 'x-access-token': token
         }
      }
       try {
           const resoponse = await axios.get(`${apiUrl}podcast/getById/${data}`,option)
           return resoponse
       } catch (error) {
         console.error(error) 
         throw error;   
       }
   }