import axios from "axios"
import apiUrl from './../../BaseUrl'  
 const getAllmodes=async()=>{
     try {
         const resoponse = await axios.get(`${apiUrl}modes/getall?page_number=1&page_size=100`)
         return resoponse
     } catch (error) {
       console.log(error) 
       throw error;   
     }
 }
 export default getAllmodes
