import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { createPlaylistApi, deletePlaylistApi, getAllbyuserID, getAllPlaylistApi, RemoveSongPlaylist, searchPlaylist, sortPlaylist, updatePlaylist,UploadNewPlaylist } from "../Api/Playlist";

interface IntesetState {
  Playlist: any[]; // Replace 'any' with the actual type of your Playlist items
  loading: boolean;
  error: string | null;
}

const initialState: IntesetState = {
  Playlist: [],
  loading: false,
  error: null,
};

export const createPlaylist = createAsyncThunk<IntesetState[], any>(
    'Playlist/createPlaylist',
    async (data: any) => {
    

      try {
        const response: AxiosResponse<any, any> = await createPlaylistApi(data);

       
        // if(response.data.isSuccess){
        //     UploadNewPlaylist({id,File})
        // }
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  
  );
  
  export const getAllPlaylist = createAsyncThunk(
    'Playlist/getAllPlaylist',
    async(modeid :any)=>{
      try {
        const response: AxiosResponse<any, any> | undefined = await getAllPlaylistApi(modeid );
        console.log(response);
        
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }

  )
  export const getAllbyuserIDPlaylist = createAsyncThunk(
    'Playlist/getAllPlaylist',
    async(modeid :any)=>{
      try {
        const response: AxiosResponse<any, any> | undefined = await getAllbyuserID(modeid);
        console.log(response);
        
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }

  )
  export const deletePlaylistSlice = createAsyncThunk<IntesetState[], any>(
    'Playlist/deletePlaylistSlice',
    async (id: any) => {
      try {
        const response: AxiosResponse<any, any> | undefined = await deletePlaylistApi(id);
        return response.data.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );
  
  export const RemoveSongPlaylists = createAsyncThunk<IntesetState[], any>(
    'Playlist/RemoveSongPlaylists',
    async (DeleteID: any) => {
      try {
        const response: AxiosResponse<any, any> | undefined = await RemoveSongPlaylist(DeleteID);
        return response.data.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );
  
  export const updatePlaylistSlice = createAsyncThunk<IntesetState[], any>(
    'Playlist/updatePlaylistSlice',
    async (data: any) => {
      try {
        const response= await updatePlaylist(data);
        console.log(response)
        return response ;
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );

   // search 
   export const fetchsearchPlaylist = createAsyncThunk<IntesetState[], any>(
    'Playlist/fetchsearchPlaylist',
    async (data: any) => {
      try {
        const response:any= await searchPlaylist(data);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );

   // sortUser
   export const fetchsortPlaylist = createAsyncThunk<IntesetState[], any>(
    'Playlist/fetchsortPlaylist',
    async (sort: any) => {
      try {
        const response:any= await sortPlaylist(sort);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );
 const PlaylistSlice = createSlice({
  name: 'PlaylistSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(createPlaylist.fulfilled, (state, action: any) => {
        console.log(action.payload)
    //   state.Playlist.push(action.payload);
      state.loading = false
    })
    .addCase(createPlaylist.pending, (state, action: PayloadAction<any>) => {

        state.loading = true
      })
    .addCase(createPlaylist.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
    })
    .addCase(getAllPlaylist.fulfilled, (state, action: PayloadAction<any>) => {
        state.Playlist= action.payload
        state.loading = false
      })
    .addCase(getAllPlaylist.pending, (state, action: PayloadAction<any>) => {
  
          state.loading = true
     })
    .addCase(getAllPlaylist.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
    })
    .addCase(deletePlaylistSlice.fulfilled, (state, action:any) => {
     
  
        state.loading = false;
      })
       // search
       .addCase(fetchsearchPlaylist.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Playlist = action.payload;
        state.loading = false;
      })

      // fetchsortUser
      .addCase(fetchsortPlaylist.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Playlist = action.payload;
        state.loading = false;
      })

  },
});
export default PlaylistSlice.reducer;
