import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { createLikedApi, deleteLikedApi, getAllbyuserID, getAllLikedApi, searchLiked, sortLiked, updateLiked,UploadNewLiked } from "../Api/Likes";

interface IntesetState {
  Liked: any[]; // Replace 'any' with the actual type of your Liked items
  loading: boolean;
  error: string | null;
}

const initialState: IntesetState = {
  Liked: [],
  loading: false,
  error: null,
};

export const createLiked = createAsyncThunk<IntesetState[], any>(
    'Liked/createLiked',
    async (data: any) => {
    

      try {
        const response: AxiosResponse<any, any> = await createLikedApi(data);

       
        // if(response.data.isSuccess){
        //     UploadNewLiked({id,File})
        // }
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  
  );
  
  export const getAllLiked = createAsyncThunk(
    'Liked/getAllLiked',
    async(modeid :any)=>{
      try {
        const response: AxiosResponse<any, any> | undefined = await getAllLikedApi(modeid);
        console.log(response);
        
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }

  )
  export const getAllbyuserIDLiked = createAsyncThunk(
    'Liked/getAllLiked',
    async(modeid :any)=>{
      try {
        const response: AxiosResponse<any, any> | undefined = await getAllbyuserID(modeid);
        console.log(response);
        
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }

  )
  export const deleteLikedSlice = createAsyncThunk<IntesetState[], any>(
    'Liked/deleteLikedSlice',
    async (id: any) => {
      try {
        const response: AxiosResponse<any, any> | undefined = await deleteLikedApi(id);
        return response.data.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );
  
  export const updateLikedSlice = createAsyncThunk<IntesetState[], any>(
    'Liked/updateLikedSlice',
    async (data: any) => {
      try {
        const response= await updateLiked(data);
        console.log(response)
        return response ;
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );

   // search 
   export const fetchsearchLiked = createAsyncThunk<IntesetState[], any>(
    'Liked/fetchsearchLiked',
    async (data: any) => {
      try {
        const response:any= await searchLiked(data);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );

   // sortUser
   export const fetchsortLiked = createAsyncThunk<IntesetState[], any>(
    'Liked/fetchsortLiked',
    async (sort: any) => {
      try {
        const response:any= await sortLiked(sort);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );
 const LikedSlice = createSlice({
  name: 'LikedSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(createLiked.fulfilled, (state, action: any) => {
        console.log(action.payload)
    //   state.Liked.push(action.payload);
      state.loading = false
    })
    .addCase(createLiked.pending, (state, action: PayloadAction<any>) => {

        state.loading = true
      })
    .addCase(createLiked.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
    })
    .addCase(getAllLiked.fulfilled, (state, action: PayloadAction<any>) => {
        state.Liked= action.payload
        state.loading = false
      })
    .addCase(getAllLiked.pending, (state, action: PayloadAction<any>) => {
  
          state.loading = true
     })
    .addCase(getAllLiked.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
    })
    .addCase(deleteLikedSlice.fulfilled, (state, action:any) => {
     
  
        state.loading = false;
      })
       // search
       .addCase(fetchsearchLiked.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Liked = action.payload;
        state.loading = false;
      })

      // fetchsortUser
      .addCase(fetchsortLiked.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Liked = action.payload;
        state.loading = false;
      })

  },
});
export default LikedSlice.reducer;
