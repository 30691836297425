import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { createMovieApi, deleteMovieApi, getAllMovieApi, getByIdMovie, searchMovie, sortMovie, updateMovie } from "../Api/Movie";

interface IntesetState {
  Movie: any[]; // Replace 'any' with the actual type of your Movie items
  loading: boolean;
  error: string | null;
}

const initialState: IntesetState = {
  Movie: [],
  loading: false,
  error: null,
};

export const createMovie = createAsyncThunk<IntesetState[], any>(
    'Movie/createMovie',
    async (data: any) => {
    const {value,File} = data;

      try {
        const response: AxiosResponse<any, any> = await createMovieApi(value);

       
        // if(response.data.isSuccess){
        //     UploadNewMovie({id,File})
        // }
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  
  );
  
  export const getAllMovie = createAsyncThunk(
    'Movie/getAllMovie',
    async(modeid :any)=>{
      try {
        const response: AxiosResponse<any, any> | undefined = await getAllMovieApi(modeid );
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }

  )

  //get ,ovie by id
  export const getByIdMovieSlice = createAsyncThunk<any, any>(
    'user/getbyidmovie',
    async (data:any) => {
      try {
        const response: AxiosResponse<any, any> | undefined = await getByIdMovie(data);
        return response.data as any;
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );

  export const deleteMovieSlice = createAsyncThunk<IntesetState[], any>(
    'Movie/deleteMovieSlice',
    async (id: any) => {
      try {
        const response: AxiosResponse<any, any> | undefined = await deleteMovieApi(id);
        return response.data.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );
  
  export const updateMovieSlice = createAsyncThunk<IntesetState[], any>(
    'Movie/updateMovieSlice',
    async (data: any) => {
      try {
        const response= await updateMovie(data);
        console.log(response)
        return response ;
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );

   // search 
   export const fetchsearchMovie = createAsyncThunk<IntesetState[], any>(
    'Movie/fetchsearchMovie',
    async (data: any) => {
      try {
        const response:any= await searchMovie(data);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );

   // sortUser
   export const fetchsortMovie = createAsyncThunk<IntesetState[], any>(
    'Movie/fetchsortMovie',
    async (sort: any) => {
      try {
        const response:any= await sortMovie(sort);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );
 const MovieSlice = createSlice({
  name: 'MovieSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(createMovie.fulfilled, (state, action: any) => {
        console.log(action.payload)
    //   state.Movie.push(action.payload);
      state.loading = false
    })
    .addCase(createMovie.pending, (state, action: PayloadAction<any>) => {

        state.loading = true
      })
    .addCase(createMovie.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
    })
    .addCase(getAllMovie.fulfilled, (state, action: PayloadAction<any>) => {
        state.Movie= action.payload
        state.loading = false
      })
    .addCase(getAllMovie.pending, (state, action: PayloadAction<any>) => {
  
          state.loading = true
     })
    .addCase(getAllMovie.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
    })
    .addCase(getByIdMovieSlice.fulfilled, (state, action: PayloadAction<any>) => {
      state.Movie= action.payload
      state.loading = false
    })
  .addCase(getByIdMovieSlice.pending, (state, action: PayloadAction<any>) => {

        state.loading = true
   })
  .addCase(getByIdMovieSlice.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
  })
    .addCase(deleteMovieSlice.fulfilled, (state, action:any) => {
     
  
        state.loading = false;
      })
       // search
       .addCase(fetchsearchMovie.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Movie = action.payload;
        state.loading = false;
      })

      // fetchsortUser
      .addCase(fetchsortMovie.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Movie = action.payload;
        state.loading = false;
      })

  },
});
export default MovieSlice.reducer;
