import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { createShowsApi, deleteShowsApi, getAllShowsApi, getByIdShows, searchShows, sortShows, updateShows } from "../Api/Shows";

interface IntesetState {
  Shows: any[]; // Replace 'any' with the actual type of your Shows items
  loading: boolean;
  error: string | null;
}

const initialState: IntesetState = {
  Shows: [],
  loading: false,
  error: null,
};

export const createShows = createAsyncThunk<IntesetState[], any>(
    'Shows/createShows',
    async (data: any) => {
    const {value,File} = data;

      try {
        const response: AxiosResponse<any, any> = await createShowsApi(value);

       
        // if(response.data.isSuccess){
        //     UploadNewShows({id,File})
        // }
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  
  );

  
  export const getAllShows = createAsyncThunk(
    'Shows/getAllShows',
    async(modeid :any)=>{
      try {
        const response: AxiosResponse<any, any> | undefined = await getAllShowsApi(modeid );
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }

  )

  //get ,ovie by id
  export const getByIdShowsSlice = createAsyncThunk<any, any>(
    'user/getbyidShows',
    async (data:any) => {
      try {
        const response: AxiosResponse<any, any> | undefined = await getByIdShows(data);
        return response.data as any;
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );

  export const deleteShowsSlice = createAsyncThunk<IntesetState[], any>(
    'Shows/deleteShowsSlice',
    async (id: any) => {
      try {
        const response: AxiosResponse<any, any> | undefined = await deleteShowsApi(id);
        return response.data.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );
  
  export const updateShowsSlice = createAsyncThunk<IntesetState[], any>(
    'Shows/updateShowsSlice',
    async (data: any) => {
      try {
        const response= await updateShows(data);
        console.log(response)
        return response ;
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );

   // search 
   export const fetchsearchShows = createAsyncThunk<IntesetState[], any>(
    'Shows/fetchsearchShows',
    async (data: any) => {
      try {
        const response:any= await searchShows(data);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );

   // sortUser
   export const fetchsortShows = createAsyncThunk<IntesetState[], any>(
    'Shows/fetchsortShows',
    async (sort: any) => {
      try {
        const response:any= await sortShows(sort);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );
 const ShowsSlice = createSlice({
  name: 'ShowsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(createShows.fulfilled, (state, action: any) => {
        console.log(action.payload)
    //   state.Shows.push(action.payload);
      state.loading = false
    })
    .addCase(createShows.pending, (state, action: PayloadAction<any>) => {

        state.loading = true
      })
    .addCase(createShows.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
    })
    .addCase(getAllShows.fulfilled, (state, action: PayloadAction<any>) => {
        state.Shows= action.payload
        state.loading = false
      })
    .addCase(getAllShows.pending, (state, action: PayloadAction<any>) => {
  
          state.loading = true
     })
    .addCase(getAllShows.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
    })
    .addCase(getByIdShowsSlice.fulfilled, (state, action: PayloadAction<any>) => {
      state.Shows= action.payload
      state.loading = false
    })
  .addCase(getByIdShowsSlice.pending, (state, action: PayloadAction<any>) => {

        state.loading = true
   })
  .addCase(getByIdShowsSlice.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
  })
    .addCase(deleteShowsSlice.fulfilled, (state, action:any) => {
     
  
        state.loading = false;
      })
       // search
       .addCase(fetchsearchShows.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Shows = action.payload;
        state.loading = false;
      })

      // fetchsortUser
      .addCase(fetchsortShows.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Shows = action.payload;
        state.loading = false;
      })

  },
});
export default ShowsSlice.reducer;
