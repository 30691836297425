import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { createEpisodesApi, deleteEpisodesApi, getAllEpisodesApi, getByIdEpisodes, searchEpisodes, sortEpisodes, updateEpisodes } from "../Api/Episodes";

interface IntesetState {
  Episodes: any[]; // Replace 'any' with the actual type of your Episodes items
  loading: boolean;
  error: string | null;
}

const initialState: IntesetState = {
  Episodes: [],
  loading: false,
  error: null,
};

export const createEpisodes = createAsyncThunk<IntesetState[], any>(
    'Episodes/createEpisodes',
    async (data: any) => {
    const {value,File} = data;

      try {
        const response: AxiosResponse<any, any> = await createEpisodesApi(value);

       
        // if(response.data.isSuccess){
        //     UploadNewEpisodes({id,File})
        // }
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  
  );

  
  export const getAllEpisodes = createAsyncThunk(
    'Episodes/getAllEpisodes',
    async(modeid :any)=>{
      try {
        const response: AxiosResponse<any, any> | undefined = await getAllEpisodesApi(modeid );
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }

  )

  //get ,ovie by id
  export const getByIdEpisodesSlice = createAsyncThunk<any, any>(
    'user/getbyidEpisodes',
    async (data:any) => {
      try {
        const response: AxiosResponse<any, any> | undefined = await getByIdEpisodes(data);
        return response.data as any;
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );

  export const deleteEpisodesSlice = createAsyncThunk<IntesetState[], any>(
    'Episodes/deleteEpisodesSlice',
    async (id: any) => {
      try {
        const response: AxiosResponse<any, any> | undefined = await deleteEpisodesApi(id);
        return response.data.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );
  
  export const updateEpisodesSlice = createAsyncThunk<IntesetState[], any>(
    'Episodes/updateEpisodesSlice',
    async (data: any) => {
      try {
        const response= await updateEpisodes(data);
        console.log(response)
        return response ;
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );

   // search 
   export const fetchsearchEpisodes = createAsyncThunk<IntesetState[], any>(
    'Episodes/fetchsearchEpisodes',
    async (data: any) => {
      try {
        const response:any= await searchEpisodes(data);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );

   // sortUser
   export const fetchsortEpisodes = createAsyncThunk<IntesetState[], any>(
    'Episodes/fetchsortEpisodes',
    async (sort: any) => {
      try {
        const response:any= await sortEpisodes(sort);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );
 const EpisodesSlice = createSlice({
  name: 'EpisodesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(createEpisodes.fulfilled, (state, action: any) => {
        console.log(action.payload)
    //   state.Episodes.push(action.payload);
      state.loading = false
    })
    .addCase(createEpisodes.pending, (state, action: PayloadAction<any>) => {

        state.loading = true
      })
    .addCase(createEpisodes.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
    })
    .addCase(getAllEpisodes.fulfilled, (state, action: PayloadAction<any>) => {
        state.Episodes= action.payload
        state.loading = false
      })
    .addCase(getAllEpisodes.pending, (state, action: PayloadAction<any>) => {
  
          state.loading = true
     })
    .addCase(getAllEpisodes.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
    })
    .addCase(getByIdEpisodesSlice.fulfilled, (state, action: PayloadAction<any>) => {
      state.Episodes= action.payload
      state.loading = false
    })
  .addCase(getByIdEpisodesSlice.pending, (state, action: PayloadAction<any>) => {

        state.loading = true
   })
  .addCase(getByIdEpisodesSlice.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
  })
    .addCase(deleteEpisodesSlice.fulfilled, (state, action:any) => {
     
  
        state.loading = false;
      })
       // search
       .addCase(fetchsearchEpisodes.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Episodes = action.payload;
        state.loading = false;
      })

      // fetchsortUser
      .addCase(fetchsortEpisodes.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Episodes = action.payload;
        state.loading = false;
      })

  },
});
export default EpisodesSlice.reducer;
