import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { createPodcastApi, deletePodcastApi, getAllPodcastApi, searchPodcast,getbyidPodcast, sortPodcast, updatePodcast,UploadNewPodcast } from "../Api/Podcast";

interface IntesetState {
  Podcast: any[]; // Replace 'any' with the actual type of your Podcast items
  loading: boolean;
  PodcastSingle:any;
  error: string | null;
}

const initialState: IntesetState = {
  Podcast: [],
  PodcastSingle:null,

  loading: false,
  error: null,
};

export const createPodcast = createAsyncThunk<IntesetState[], any>(
    'Podcast/createPodcast',
    async (data: any) => {
    const {value,File}=data

      try {
        const response: AxiosResponse<any, any> = await createPodcastApi(value);

       
        // if(response.data.isSuccess){
        //     UploadNewPodcast({id,File})
        // }
        return response.data
      } catch (error) {
        console.error("Error fetching Podcast:", error);
        throw error;
      }
    }
  
  );
  export const getbyidPodcasts = createAsyncThunk<IntesetState[], any>(
    'user/getbyidusers',
    async (data:any) => {
      try {
        const response: AxiosResponse<any, any> = await getbyidPodcast(data);
        console.log(response);
        
        return response.data as any;
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );
  export const getAllPodcast = createAsyncThunk(
    'Podcast/getAllPodcast',
    async(modeid :any)=>{
      try {
        const response: AxiosResponse<any, any> | undefined = await getAllPodcastApi(modeid );
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }

  )
  export const deletePodcastSlice = createAsyncThunk<IntesetState[], any>(
    'Podcast/deletePodcastSlice',
    async (id: any) => {
      try {
        const response: AxiosResponse<any, any> | undefined = await deletePodcastApi(id);
        return response.data.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );
  
  export const updatePodcastSlice = createAsyncThunk<IntesetState[], any>(
    'Podcast/updatePodcastSlice',
    async (data: any) => {
      try {
        const response= await updatePodcast(data);
        console.log(response)
        return response ;
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );

   // search 
   export const fetchsearchPodcast = createAsyncThunk<IntesetState[], any>(
    'Podcast/fetchsearchPodcast',
    async (data: any) => {
      try {
        const response:any= await searchPodcast(data);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );

   // sortUser
   export const fetchsortPodcast = createAsyncThunk<IntesetState[], any>(
    'Podcast/fetchsortPodcast',
    async (sort: any) => {
      try {
        const response:any= await sortPodcast(sort);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );
 const PodcastSlice = createSlice({
  name: 'PodcastSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(createPodcast.fulfilled, (state, action: any) => {
        console.log(action.payload)
    //   state.Podcast.push(action.payload);
      state.loading = false
    })
    .addCase(createPodcast.pending, (state, action: PayloadAction<any>) => {

        state.loading = true
      })
    .addCase(createPodcast.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
    })
    .addCase(getbyidPodcasts.fulfilled, (state, action: PayloadAction<any>) => {
        state.PodcastSingle= action.payload
        state.loading = false
      })
    .addCase(getbyidPodcasts.pending, (state, action: PayloadAction<any>) => {
  
          state.loading = true
     })
    .addCase(getbyidPodcasts.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
    })
    .addCase(getAllPodcast.fulfilled, (state, action: PayloadAction<any>) => {
      state.Podcast= action.payload
      state.loading = false
    })
  .addCase(getAllPodcast.pending, (state, action: PayloadAction<any>) => {

        state.loading = true
   })
  .addCase(getAllPodcast.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
  })
    .addCase(deletePodcastSlice.fulfilled, (state, action:any) => {
     
  
        state.loading = false;
      })
       // search
       .addCase(fetchsearchPodcast.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Podcast = action.payload;
        state.loading = false;
      })

      // fetchsortUser
      .addCase(fetchsortPodcast.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Podcast = action.payload;
        state.loading = false;
      })

  },
});
export default PodcastSlice.reducer;
