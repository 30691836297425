import axios from "axios";
import  BaseUrl  from "../../BaseUrl";
import apiUrl from '../../BaseUrl'  
const token:any = localStorage.getItem('token');


export const createAlbumApi =async(data:any)=>{
    try {
        const response = await axios.post(`${BaseUrl}album/create`,data)
        return response
    } catch (error) {
        console.log(error)
      throw error;   

    }
}

export const getAllAlbumApi = async(modeid :any)=>{
    try {
        const response = await axios.get(`${BaseUrl}album/getall/${modeid}?page_no=1&page_size=100`)
        console.log(response,'api');
        
        return response
    } catch (error) {
        console.log(error)  
      throw error;   
        
    }
}

export const deleteAlbumApi=async(id:any)=>{
   
    try {
        const resoponse = await axios.delete(`${BaseUrl}album/remove/${id}`)
        return resoponse
    } catch (error) {
      console.log(error) 
      throw error;   
    }
}
export const updateAlbum = async (data: any) => {
    const { editId, values } = data;
    const options = {
      method:'PUT',
      headers: {
        'content-type':'application/json',
        
      },
      body: JSON.stringify(values),
    };
    try {
      const response:any = await fetch(
        `${apiUrl}album/update/${editId}`,
        options
      );
      const dataaa = await response.json()
      console.log(dataaa)

      return  dataaa; // Fixed the variable name here
    } catch (error) {
      console.log(error);
      throw error;
    }
  };


  export const searchUser = async (name: any) => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
  
    try {
      const response = await fetch(
        `${apiUrl}users/search?name=${name}`,
        options
      );
  
      if (!response.ok) {
        throw new Error(`Failed to fetch data. Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log(responseData);
      
      return responseData; // Assuming the response is already JSON, no need to stringify.
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  export const searchAlbum = async (name: any) => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
  
    try {
      const response = await fetch(
        `${apiUrl}album/search/6565dbb8f55b057bd1fc4a82?name=${name}`,
        options
      );
  
      if (!response.ok) {
        throw new Error(`Failed to fetch data. Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log(responseData);
      
      return responseData; // Assuming the response is already JSON, no need to stringify.
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
    
  export const sortAlbum =async(sort:any)=>{
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  
    try {
      const response = await fetch(
        `${apiUrl}album/sort/6565dbb8f55b057bd1fc4a82?sort=${sort}`,
        options
      );
      const responseData = await response.json();
      console.log(responseData);
      
      return responseData; 
    } catch (error) {
      console.error(error);
      throw error;
    }
  }


 export const UploadNewAlbum = async (data:any) => {
    const { File,id,config } = data;
  
    const formData = new FormData();
    formData.append('image', File); 
    
  
    try {
      const response = await axios.put(`${apiUrl}album/UploadAlbumImage/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress:config
      })
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  export const updatestatus = async (data: any) => {
    const { editId, status } = data;
    const options = {
      method:'PUT',
      headers: {
        'content-type':'application/json',
        'x-access-token':
          token,
      },
      body: JSON.stringify({status:status}),
    };
    try {
      const response:any = await fetch(
        `${apiUrl}album/update/${editId}`,
        options
      );
      return response.data; // Fixed the variable name here
    } catch (error) {
      console.error(error);
      throw error;
    }
  };