import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { createSongApi, deleteSongApi, getAllSongApi, searchSong, sortSong, updateSong,UploadNewSong } from "../Api/Song";

interface IntesetState {
  Song: any[]; // Replace 'any' with the actual type of your Song items
  loading: boolean;
  error: string | null;
}

const initialState: IntesetState = {
  Song: [],
  loading: false,
  error: null,
};

export const createSong = createAsyncThunk<IntesetState[], any>(
    'Song/createSong',
    async (data: any) => {
    const {value,File}=data

      try {
        const response: AxiosResponse<any, any> = await createSongApi(value);

       
        // if(response.data.isSuccess){
        //     UploadNewSong({id,File})
        // }
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  
  );
  
  export const getAllSong = createAsyncThunk(
    'Song/getAllSong',
    async(modeid :any)=>{
      try {
        const response: AxiosResponse<any, any> | undefined = await getAllSongApi(modeid );
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }

  )
  export const deleteSongSlice = createAsyncThunk<IntesetState[], any>(
    'Song/deleteSongSlice',
    async (id: any) => {
      try {
        const response: AxiosResponse<any, any> | undefined = await deleteSongApi(id);
        return response.data.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );
  
  export const updateSongSlice = createAsyncThunk<IntesetState[], any>(
    'Song/updateSongSlice',
    async (data: any) => {
      try {
        const response= await updateSong(data);
        console.log(response)
        return response ;
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );

   // search 
   export const fetchsearchSong = createAsyncThunk<IntesetState[], any>(
    'Song/fetchsearchSong',
    async (data: any) => {
      try {
        const response:any= await searchSong(data);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );

   // sortUser
   export const fetchsortSong = createAsyncThunk<IntesetState[], any>(
    'Song/fetchsortSong',
    async (sort: any) => {
      try {
        const response:any= await sortSong(sort);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );
 const SongSlice = createSlice({
  name: 'SongSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(createSong.fulfilled, (state, action: any) => {
        console.log(action.payload)
    //   state.Song.push(action.payload);
      state.loading = false
    })
    .addCase(createSong.pending, (state, action: PayloadAction<any>) => {

        state.loading = true
      })
    .addCase(createSong.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
    })
    .addCase(getAllSong.fulfilled, (state, action: PayloadAction<any>) => {
        state.Song= action.payload
        state.loading = false
      })
    .addCase(getAllSong.pending, (state, action: PayloadAction<any>) => {
  
          state.loading = true
     })
    .addCase(getAllSong.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
    })
    .addCase(deleteSongSlice.fulfilled, (state, action:any) => {
     
  
        state.loading = false;
      })
       // search
       .addCase(fetchsearchSong.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Song = action.payload;
        state.loading = false;
      })

      // fetchsortUser
      .addCase(fetchsortSong.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Song = action.payload;
        state.loading = false;
      })

  },
});
export default SongSlice.reducer;
