import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { createTagCategoryApi, deleteTagCategoryApi, getAllTagCategoryApi, searchTagCategory, sortTagCategory, updateTagCategory,UploadNewTagCategory } from "../Api/TagCategory";

interface IntesetState {
  TagCategory: any[]; // Replace 'any' with the actual type of your TagCategory items
  loading: boolean;
  error: string | null;
}

const initialState: IntesetState = {
  TagCategory: [],
  loading: false,
  error: null,
};

export const createTagCategory = createAsyncThunk<IntesetState[], any>(
    'TagCategory/createTagCategory',
    async (data: any) => {
    const { value } = data;

      try {
        const response: AxiosResponse<any, any> = await createTagCategoryApi(value);

       
        // if(response.data.isSuccess){
        //     UploadNewTagCategory({id,File})
        // }
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  
  );
  
  export const getAllTagCategory = createAsyncThunk(
    'TagCategory/getAllTagCategory',
    async( modeid : any )=>{
      try {
        const response: AxiosResponse<any, any> | undefined = await getAllTagCategoryApi( modeid );
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }

  )
  export const deleteTagCategorySlice = createAsyncThunk<IntesetState[], any>(
    'TagCategory/deleteTagCategorySlice',
    async (id: any) => {
      try {
        const response: AxiosResponse<any, any> | undefined = await deleteTagCategoryApi(id);
        return response.data.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );
  
  export const updateTagCategorySlice = createAsyncThunk<IntesetState[], any>(
    'TagCategory/updateTagCategorySlice',
    async (data: any) => {
      try {
        const response= await updateTagCategory(data);
        console.log(response)
        return response ;
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );

   // search 
   export const fetchsearchTagCategory = createAsyncThunk<IntesetState[], any>(
    'TagCategory/fetchsearchTagCategory',
    async (data: any) => {
      try {
        const response:any= await searchTagCategory(data);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );

   // sortUser
   export const fetchsortTagCategory = createAsyncThunk<IntesetState[], any>(
    'TagCategory/fetchsortTagCategory',
    async (sort: any) => {
      try {
        const response:any= await sortTagCategory(sort);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );
 const TagCategorySlice = createSlice({
  name: 'TagCategorySlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(createTagCategory.fulfilled, (state, action: any) => {
        console.log(action.payload)
    //   state.TagCategory.push(action.payload);
      state.loading = false
    })
    .addCase(createTagCategory.pending, (state, action: PayloadAction<any>) => {

        state.loading = true
      })
    .addCase(createTagCategory.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
    })
    .addCase(getAllTagCategory.fulfilled, (state, action: PayloadAction<any>) => {
        state.TagCategory= action.payload
        state.loading = false
      })
    .addCase(getAllTagCategory.pending, (state, action: PayloadAction<any>) => {
  
          state.loading = true
     })
    .addCase(getAllTagCategory.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
    })
    .addCase(deleteTagCategorySlice.fulfilled, (state, action:any) => {
     
  
        state.loading = false;
      })
       // search
       .addCase(fetchsearchTagCategory.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.TagCategory = action.payload;
        state.loading = false;
      })

      // fetchsortUser
      .addCase(fetchsortTagCategory.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.TagCategory = action.payload;
        state.loading = false;
      })

  },
});
export default TagCategorySlice.reducer;
