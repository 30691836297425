import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import {  createProfile, createSingera, deleteSinger, getAllSinger, searchSinger, sortSinger, updateSinger,getbyidSinger, searchSingerbyAge, RemoveImageintoGallery, uploadGalleryImage } from "../Api/Singer";

interface Singer {
    _id:string;
  name: string;
  password: string;
  email: string;
  DOB: string;
  phone_number: string;
  mode: string;
  iAm: "Male" | "Female";
  looking: "Male" | "Female";
  marial: "Single" | "Married";
  status: "active" | "inactive";
  city: string;
}

interface SingerState {
  Singers: Singer[];
  Singer: any;
  page:any;
  loading: boolean;
  error: null | string;
}

const initialState: SingerState = {
  Singers: [],
  Singer:[],
  page:1,
  loading: false,
  error: null,
};

export const createSinger = createAsyncThunk<Singer[], any>(
  'Singer/createSinger',
  async (data: any) => {
    const {values,avatarFil}=data
    try {
      const response: AxiosResponse<any, any> | undefined = await createSingera(values);
      const id = response.data.data._id
      if(response.data.isSuccess){
        uploadGalleryImage({id,avatarFil})
      }
      return response.data as Singer[];
    } catch (error) {
      console.error("Error creating Singer:", error);
      throw error;
    }
  }
);


export const uploadGalleryImages = createAsyncThunk<Singer[], any>(
  'Singer/uploadGalleryImages',
  async (data: any) => {
    const {id,avatarFil}=data
    try {
       
    const response :any= await  uploadGalleryImage({id,avatarFil})
      
      return response ;
    } catch (error) {
      console.error("Error creating Singer:", error);
      throw error;
    }
  }
);



export const fetchSinger = createAsyncThunk<Singer[], any>(
  'Singer/fetchSinger',
  async (data:any) => {
    try {
      const response: AxiosResponse<any, any> | undefined = await getAllSinger(data);
      return response.data as Singer[];
    } catch (error) {
      console.error("Error fetching Singers:", error);
      throw error;
    }
  }
);

export const getbyidSingers = createAsyncThunk<Singer[], any>(
  'Singer/getbyidSingers',
  async (data:any) => {
    try {
      const response: AxiosResponse<any, any> | undefined = await getbyidSinger(data);
      return response.data as Singer[];
    } catch (error) {
      console.error("Error fetching Singers:", error);
      throw error;
    }
  }
);
export const fetchdeleteSinger = createAsyncThunk<Singer[], any>(
    'Singer/fetchdeleteSinger',
    async (id: any) => {
      try {
        const response: AxiosResponse<any, any> | undefined = await deleteSinger(id);
        return response.data as Singer[];
      } catch (error) {
        console.error("Error fetching Singers:", error);
        throw error;
      }
    }
  );


  
  export const fetchupdateSinger = createAsyncThunk<Singer[], any>(
    'Singer/fetchdeleteSinger',
    async (data:any) => {
      
      try {
        const response= await updateSinger(data);
        return response.data as Singer[];
      } catch (error) {
        console.error("Error fetching Singers:", error);
        throw error;
      }
    }
  );

  export const RemoveImageintoGallerys = createAsyncThunk<Singer[], any>(
    'Singer/RemoveImageintoGallerys',
    async (data:any) => {
      const {id ,item}=data
      
      try {
        const response = await RemoveImageintoGallery(data);

        
        return response as any;
      } catch (error) {
        console.error("Error fetching Singers:", error);
        throw error;
      }
    }
  );
  // search 
  export const fetchsearchSinger = createAsyncThunk<Singer[], any>(
    'Singer/fetchsearchSinger',
    async (data: any) => {
      try {
        const response:any= await searchSinger(data);
        return response as Singer[];
      } catch (error) {
        console.error("Error creating Singer:", error);
        throw error;
      }
    }
  );
  export const searchSingerbyAges = createAsyncThunk<Singer[], any>(
    'Singer/searchSingerbyAges',
    async (data: any) => {
 
      try {
        const response:any= await searchSingerbyAge(data);
        return response as Singer[];
      } catch (error) {
        console.error("Error creating Singer:", error);
        throw error;
      }
    }
  );
  // sortSinger
  export const fetchsortSinger = createAsyncThunk<Singer[], any>(
    'Singer/fetchsortSinger',
    async (sort: any) => {
      try {
        const response:any= await sortSinger(sort);
        return response as Singer[];
      } catch (error) {
        console.error("Error creating Singer:", error);
        throw error;
      }
    }
  );
const SingerSlice:any = createSlice({
  name: 'SingerSlice',
  initialState,
  reducers: {
    setPage:(state,action)=>{
      state.page=action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSinger.fulfilled, (state, action:any) => {
        // state.Singers = action.payload;

        state.loading = false;
      })
      .addCase(createSinger.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createSinger.rejected, (state, action) => {
        state.loading = false;
        state.error = "error";
      })
      .addCase(fetchSinger.fulfilled, (state, action) => {
        state.Singers = action.payload;
        state.loading = false;
      })
      .addCase(getbyidSingers.fulfilled, (state, action) => {
        state.Singer = action.payload;
        state.loading = false;
      })
    //   delete
      .addCase(fetchdeleteSinger.fulfilled, (state, action) => {
        // const alldata= state.Singers.filter((items:any)=>items._id!==action.payload._id)
        // state.Singers.push(...action.payload);
        state.loading = false;
      })
      // search
      .addCase(fetchsearchSinger.fulfilled,(state,action)=>{
        state.Singers = action.payload;
        state.loading = false;
      })
       // Filter by age
       .addCase(searchSingerbyAges.fulfilled,(state,action)=>{
        state.Singers = action.payload;
        state.loading = false;
      })
      // fetchsortSinger
      .addCase(fetchsortSinger.fulfilled,(state,action)=>{
        state.Singers = action.payload;
        state.loading = false;
      })
      .addCase(RemoveImageintoGallerys.fulfilled,(state,action)=>{
       
        state.loading = false;
      })
  },
});
export const { setPage } = SingerSlice.actions

export default SingerSlice.reducer;
