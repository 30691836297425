import axios from "axios"
import apiUrl from './../../BaseUrl'  

 const createLogin=async(data:any)=>{
    try {
        const resoponse = await axios.post(`${apiUrl}admins/login`,data)
        console.log(resoponse)
       
        return resoponse
    } catch (error) {
      console.log(error)
      throw error;    
    }
}


export const forgotPassword = async(data:any)=>{
  try {
    const resoponse = await axios.post(`${apiUrl}admins/forgot-password`,data)
        console.log(resoponse)
        return resoponse
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const verifyOTP = async(data:any)=>{
  try {
    const resoponse = await axios.post(`${apiUrl}admins/verifyOTP`,data)
        console.log(resoponse)
        return resoponse
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export default createLogin