import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { createTagApi, deleteTagApi, getAllTagApi, searchTag, sortTag, updateTag,UploadNewTag } from "../Api/Tag";

interface IntesetState {
  Tag: any[]; // Replace 'any' with the actual type of your Tag items
  loading: boolean;
  error: string | null;
}

const initialState: IntesetState = {
  Tag: [],
  loading: false,
  error: null,
};

export const createTag = createAsyncThunk<IntesetState[], any>(
    'Tag/createTag',
    async (data: any) => {
    const { value } = data;

      try {
        const response: AxiosResponse<any, any> = await createTagApi(value);

       
        // if(response.data.isSuccess){
        //     UploadNewTag({id,File})
        // }
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  
  );

  
  
  export const getAllTag = createAsyncThunk(
    'Tag/getAllTag',
    async(modeid :any)=>{
      try {
        const response: AxiosResponse<any, any> | undefined = await getAllTagApi(modeid );
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }

  )
  export const deleteTagSlice = createAsyncThunk<IntesetState[], any>(
    'Tag/deleteTagSlice',
    async (id: any) => {
      try {
        const response: AxiosResponse<any, any> | undefined = await deleteTagApi(id);
        return response.data.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );
  
  export const updateTagSlice = createAsyncThunk<IntesetState[], any>(
    'Tag/updateTagSlice',
    async (data: any) => {
      try {
        const response= await updateTag(data);
        console.log(response)
        return response ;
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );

   // search 
   export const fetchsearchTag = createAsyncThunk<IntesetState[], any>(
    'Tag/fetchsearchTag',
    async (data: any) => {
      try {
        const response:any= await searchTag(data);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );

   // sortUser
   export const fetchsortTag = createAsyncThunk<IntesetState[], any>(
    'Tag/fetchsortTag',
    async (sort: any) => {
      try {
        const response:any= await sortTag(sort);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );
 const TagSlice = createSlice({
  name: 'TagSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(createTag.fulfilled, (state, action: any) => {
        console.log(action.payload)
    //   state.Tag.push(action.payload);
      state.loading = false
    })
    .addCase(createTag.pending, (state, action: PayloadAction<any>) => {

        state.loading = true
      })
    .addCase(createTag.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
    })
    .addCase(getAllTag.fulfilled, (state, action: PayloadAction<any>) => {
        state.Tag= action.payload
        state.loading = false
      })
    .addCase(getAllTag.pending, (state, action: PayloadAction<any>) => {
  
          state.loading = true
     })
    .addCase(getAllTag.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
    })
    .addCase(deleteTagSlice.fulfilled, (state, action:any) => {
     
  
        state.loading = false;
      })
       // search
       .addCase(fetchsearchTag.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Tag = action.payload;
        state.loading = false;
      })

      // fetchsortUser
      .addCase(fetchsortTag.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Tag = action.payload;
        state.loading = false;
      })

  },
});
export default TagSlice.reducer;
