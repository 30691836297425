import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { createPodcastEpisodeApi, deletePodcastEpisodeApi, getAllPodcastEpisodeApi, searchPodcastEpisode, sortPodcastEpisode, updatePodcastEpisode,UploadNewPodcastEpisode } from "../Api/PodcastEpisode";

interface IntesetState {
  PodcastEpisode: any[]; // Replace 'any' with the actual type of your PodcastEpisode items
  loading: boolean;
  error: string | null;
}

const initialState: IntesetState = {
  PodcastEpisode: [],
  loading: false,
  error: null,
};

export const createPodcastEpisode = createAsyncThunk<IntesetState[], any>(
    'PodcastEpisode/createPodcastEpisode',
    async (data: any) => {
    const {value,File}=data

      try {
        const response: AxiosResponse<any, any> = await createPodcastEpisodeApi(value);

       
        // if(response.data.isSuccess){
        //     UploadNewPodcastEpisode({id,File})
        // }
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  
  );
  
  export const getAllPodcastEpisode = createAsyncThunk(
    'PodcastEpisode/getAllPodcastEpisode',
    async(modeid :any)=>{
      try {
        const response: AxiosResponse<any, any> = await getAllPodcastEpisodeApi(modeid );
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }

  )
  export const deletePodcastEpisodeSlice = createAsyncThunk<IntesetState[], any>(
    'PodcastEpisode/deletePodcastEpisodeSlice',
    async (id: any) => {
      try {
        const response: AxiosResponse<any, any>  = await deletePodcastEpisodeApi(id);
        return response.data.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );
  
  export const updatePodcastEpisodeSlice = createAsyncThunk<IntesetState[], any>(
    'PodcastEpisode/updatePodcastEpisodeSlice',
    async (data: any) => {
      try {
        const response= await updatePodcastEpisode(data);
        console.log(response)
        return response ;
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );

   // search 
   export const fetchsearchPodcastEpisode = createAsyncThunk<IntesetState[], any>(
    'PodcastEpisode/fetchsearchPodcastEpisode',
    async (data: any) => {
      try {
        const response:any= await searchPodcastEpisode(data);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );

   // sortUser
   export const fetchsortPodcastEpisode = createAsyncThunk<IntesetState[], any>(
    'PodcastEpisode/fetchsortPodcastEpisode',
    async (sort: any) => {
      try {
        const response:any= await sortPodcastEpisode(sort);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );
 const PodcastEpisodeSlice = createSlice({
  name: 'PodcastEpisodeSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(createPodcastEpisode.fulfilled, (state, action: any) => {
        console.log(action.payload)
    //   state.PodcastEpisode.push(action.payload);
      state.loading = false
    })
    .addCase(createPodcastEpisode.pending, (state, action: PayloadAction<any>) => {

        state.loading = true
      })
    .addCase(createPodcastEpisode.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
    })
    .addCase(getAllPodcastEpisode.fulfilled, (state, action: PayloadAction<any>) => {
        state.PodcastEpisode= action.payload
        state.loading = false
      })
    .addCase(getAllPodcastEpisode.pending, (state, action: PayloadAction<any>) => {
  
          state.loading = true
     })
    .addCase(getAllPodcastEpisode.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
    })
    .addCase(deletePodcastEpisodeSlice.fulfilled, (state, action:any) => {
     
  
        state.loading = false;
      })
       // search
       .addCase(fetchsearchPodcastEpisode.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.PodcastEpisode = action.payload;
        state.loading = false;
      })

      // fetchsortUser
      .addCase(fetchsortPodcastEpisode.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.PodcastEpisode = action.payload;
        state.loading = false;
      })

  },
});
export default PodcastEpisodeSlice.reducer;
