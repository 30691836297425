import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { createSeasionApi, deleteSeasionApi, getAllSeasionApi, getByIdSeasion, searchSeasion, sortSeasion, updateSeasion } from "../Api/Seasion";

interface IntesetState {
  Seasion: any[]; // Replace 'any' with the actual type of your Seasion items
  loading: boolean;
  error: string | null;
}

const initialState: IntesetState = {
  Seasion: [],
  loading: false,
  error: null,
};

export const createSeasion = createAsyncThunk<IntesetState[], any>(
    'Seasion/createSeasion',
    async (data: any) => {
    const {value,File} = data;

      try {
        const response: AxiosResponse<any, any> = await createSeasionApi(value);

       
        // if(response.data.isSuccess){
        //     UploadNewSeasion({id,File})
        // }
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  
  );

  
  export const getAllSeasion = createAsyncThunk(
    'Seasion/getAllSeasion',
    async(modeid :any)=>{
      try {
        const response: AxiosResponse<any, any> | undefined = await getAllSeasionApi(modeid );
        return response.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }

  )

  //get ,ovie by id
  export const getByIdSeasionSlice = createAsyncThunk<any, any>(
    'user/getbyidSeasion',
    async (data:any) => {
      try {
        const response: AxiosResponse<any, any> | undefined = await getByIdSeasion(data);
        return response.data as any;
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );

  export const deleteSeasionSlice = createAsyncThunk<IntesetState[], any>(
    'Seasion/deleteSeasionSlice',
    async (id: any) => {
      try {
        const response: AxiosResponse<any, any> | undefined = await deleteSeasionApi(id);
        return response.data.data
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );
  
  export const updateSeasionSlice = createAsyncThunk<IntesetState[], any>(
    'Seasion/updateSeasionSlice',
    async (data: any) => {
      try {
        const response= await updateSeasion(data);
        console.log(response)
        return response ;
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    }
  );

   // search 
   export const fetchsearchSeasion = createAsyncThunk<IntesetState[], any>(
    'Seasion/fetchsearchSeasion',
    async (data: any) => {
      try {
        const response:any= await searchSeasion(data);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );

   // sortUser
   export const fetchsortSeasion = createAsyncThunk<IntesetState[], any>(
    'Seasion/fetchsortSeasion',
    async (sort: any) => {
      try {
        const response:any= await sortSeasion(sort);
        console.log(response.data);
        return response ;
      } catch (error) {
        console.error("Error creating user:", error);
        throw error;
      }
    }
  );
 const SeasionSlice = createSlice({
  name: 'SeasionSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(createSeasion.fulfilled, (state, action: any) => {
        console.log(action.payload)
    //   state.Seasion.push(action.payload);
      state.loading = false
    })
    .addCase(createSeasion.pending, (state, action: PayloadAction<any>) => {

        state.loading = true
      })
    .addCase(createSeasion.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
    })
    .addCase(getAllSeasion.fulfilled, (state, action: PayloadAction<any>) => {
        state.Seasion= action.payload
        state.loading = false
      })
    .addCase(getAllSeasion.pending, (state, action: PayloadAction<any>) => {
  
          state.loading = true
     })
    .addCase(getAllSeasion.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
    })
    .addCase(getByIdSeasionSlice.fulfilled, (state, action: PayloadAction<any>) => {
      state.Seasion= action.payload
      state.loading = false
    })
  .addCase(getByIdSeasionSlice.pending, (state, action: PayloadAction<any>) => {

        state.loading = true
   })
  .addCase(getByIdSeasionSlice.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false
  })
    .addCase(deleteSeasionSlice.fulfilled, (state, action:any) => {
     
  
        state.loading = false;
      })
       // search
       .addCase(fetchsearchSeasion.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Seasion = action.payload;
        state.loading = false;
      })

      // fetchsortUser
      .addCase(fetchsortSeasion.fulfilled,(state,action)=>{
        console.log(action.payload)
        state.Seasion = action.payload;
        state.loading = false;
      })

  },
});
export default SeasionSlice.reducer;
