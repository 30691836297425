import axios from "axios";
import  BaseUrl  from "../../BaseUrl";
import apiUrl from '../../BaseUrl'  
const token:any = localStorage.getItem('token');


export const updatestatus = async (data: any) => {
  const { editId, status } = data;
  const options = {
    method:'PUT',
    headers: {
      'content-type':'application/json',
      'x-access-token':
        token,
    },
    body: JSON.stringify({status}),
  };
  try {
    const response:any = await fetch(
      `${BaseUrl}Content/update/${editId}`,
      options
    );
    return response.data; // Fixed the variable name here
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const createSeasionApi =async(data:any)=>{
    try {
        const response = await axios.post(`${BaseUrl}season/create`,data)
        return response
    } catch (error) {
        console.log(error)
      throw error;   

    }
}


export const getAllSeasionApi = async(modeid :any)=>{
    try {
        const response = await axios.get(`${BaseUrl}webseries/getall/${modeid}?page_no=1&page_size=100`)
        return response
    } catch (error) {
        console.log(error)  
      throw error;   
        
    }
}

export const getByIdSeasion = async(data:any)=>{
  
  const option={
   headers: {
             'x-access-token': token
     }
  }
   try {
       const resoponse = await axios.get(`${BaseUrl}Content/getById/${data}`,option)
       return resoponse
   } catch (error) {
     console.error(error) 
     throw error;   
   }
}

export const deleteSeasionApi=async(id:any)=>{
   
    try {
        const resoponse = await axios.delete(`${BaseUrl}Content/remove/${id}`)
        return resoponse
    } catch (error) {
      console.log(error) 
      throw error;   
    }
}
export const updateSeasion = async (data: any) => {
    const { id, values } = data;
    const options = {
      method:'PUT',
      headers: {
        'content-type':'application/json',
      },
      body: JSON.stringify(values),
    };
    try {
      const response:any = await fetch(
        `${apiUrl}Content/update/${id}`,
        options
      );
      const dataaa = await response.json()
      console.log(dataaa)

      return  dataaa; // Fixed the variable name here
    } catch (error) {
      console.log(error);
      throw error;
    }
  };


  export const searchUser = async (name: any) => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
  
    try {
      const response = await fetch(
        `${apiUrl}users/search?name=${name}`,
        options
      );
  
      if (!response.ok) {
        throw new Error(`Failed to fetch data. Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log(responseData);
      
      return responseData; // Assuming the response is already JSON, no need to stringify.
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  export const searchSeasion = async (name: any) => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
  
    try {
      const response = await fetch(
        `${apiUrl}Seasion/search/6565dbb8f55b057bd1fc4a82?name=${name}`,
        options
      );
  
      if (!response.ok) {
        throw new Error(`Failed to fetch data. Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log(responseData);
      
      return responseData; // Assuming the response is already JSON, no need to stringify.
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
    
  export const sortSeasion =async(sort:any)=>{
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  
    try {
      const response = await fetch(
        `${apiUrl}Seasion/sort/6565dbb8f55b057bd1fc4a82?sort=${sort}`,
        options
      );
      const responseData = await response.json();
      console.log(responseData);
      
      return responseData; 
    } catch (error) {
      console.error(error);
      throw error;
    }
  }


 export const UploadSeasionThumb = async (data:any) => {
    const { File ,id ,config } = data;

    const formData = new FormData();
    formData.append('image', File); 
  
    try {
      const response = await axios.put(`${apiUrl}season/UploadSeasonImage/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress:config
       })
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  export const UpdateSeasionThumb = async (data:any) => {
    const { File ,id ,config } = data;

    const formData = new FormData();
    formData.append('image', File); 
  
    try {
      const response = await axios.put(`${apiUrl}season/UploadSeasonImage/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress:config
       })
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };




  export const UploadSeasionTrailer = async (data:any) => {
    const { File ,id ,config } = data;

    const formData = new FormData();
    formData.append('trailer', File); 
  
    try {
      const response = await axios.put(`${apiUrl}season/UploadVideoTrailer/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress:config
       })
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  export const UploadSeasion = async (data:any) => {
    const { File ,id ,config } = data;

    const formData = new FormData();
    formData.append('video', File); 
  
    try {
      const response = await axios.put(`${apiUrl}Content/uploadVideo/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress:config
       })
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  export const UpdateSeasion = async (data:any) => {
    const { File ,id ,config } = data;

    const formData = new FormData();
    formData.append('video', File); 
  
    try {
      const response = await axios.put(`${apiUrl}Content/UpdateVideo/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress:config
       })
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };