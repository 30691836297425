import axios from "axios";
import  BaseUrl  from "../../BaseUrl";
import apiUrl from '../../BaseUrl'  
const token:any = localStorage.getItem('token');


export const createPodcastEpisodeApi =async(data:any)=>{
    try {
        const response = await axios.post(`${BaseUrl}podcast-episode/create`,data)
        return response
    } catch (error) {
        console.log(error)
      throw error;   

    }
}

export const getAllPodcastEpisodeApi = async(modeid :any)=>{
    try {
        const response = await axios.get(`${BaseUrl}podcast-episode/getall/${modeid}?page_no=1&page_size=100`)
        return response
    } catch (error) {
        console.log(error)  
      throw error;   
        
    }
}

export const deletePodcastEpisodeApi=async(id:any)=>{
   
    try {
        const resoponse = await axios.delete(`${BaseUrl}podcast-episode/remove/${id}`)
        return resoponse
    } catch (error) {
      console.log(error) 
      throw error;   
    }
}
export const updatePodcastEpisode = async (data: any) => {
    const { editId, values } = data;
    const options = {
      method:'PUT',
      headers: {
        'content-type':'application/json',
        
      },
      body: JSON.stringify(values),
    };
    try {
      const response:any = await fetch(
        `${apiUrl}podcast-episode/PodcastEpisodeupdate/${editId}`,
        options
      );
      const dataaa = await response.json()
  

      return  dataaa; // Fixed the variable name here
    } catch (error) {
      console.log(error);
      throw error;
    }
  };




export const updateStatus = async (data: any) => {
  const { editId, value } = data;
  const options = {
    method:'PUT',
    headers: {
      'content-type':'application/json',
      
    },
    body: JSON.stringify({status:value}),
  };
  try {
    const response:any = await fetch(
      `${apiUrl}podcast-episode/PodcastEpisodeupdate/${editId}`,
      options
    );
    const dataaa = await response.json()


    return  dataaa; // Fixed the variable name here
  } catch (error) {
    console.log(error);
    throw error;
  }
};
 

  export const searchUser = async (name: any) => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
  
    try {
      const response = await fetch(
        `${apiUrl}users/search?name=${name}`,
        options
      );
  
      if (!response.ok) {
        throw new Error(`Failed to fetch data. Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log(responseData);
      
      return responseData; // Assuming the response is already JSON, no need to stringify.
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  export const searchPodcastEpisode = async (name: any) => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
  
    try {
      const response = await fetch(
        `${apiUrl}podcast-episode/search/6565dbb8f55b057bd1fc4a82?name=${name}`,
        options
      );
  
      if (!response.ok) {
        throw new Error(`Failed to fetch data. Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log(responseData);
      
      return responseData; // Assuming the response is already JSON, no need to stringify.
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
    
  export const sortPodcastEpisode =async(sort:any)=>{
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  
    try {
      const response = await fetch(
        `${apiUrl}podcast-episode/sort/6565dbb8f55b057bd1fc4a82?sort=${sort}`,
        options
      );
      const responseData = await response.json();
      console.log(responseData);
      
      return responseData; 
    } catch (error) {
      console.error(error);
      throw error;
    }
  }


 export const UploadNewPodcastEpisode = async (data:any) => {
    const { File,id,config } = data;
  
    const formData = new FormData();
    formData.append('image', File); 
    
  
    try {
      const response = await axios.put(`${apiUrl}podcast-episode/uploadPodcastEpisodeImage/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress:config
      })
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };


  export const createProfile = async (data:any) => {
    const { File,id,config } = data;
    
    const formData = new FormData();
    formData.append('image', File); 
    
  
    try {
      const response = await axios.put(`${apiUrl}podcast-episode/uploadPodcastEpisodeImage/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress:config
      })
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }}